@font-face {
    font-family: 'NotoColorEmoji';
    src: local('NotoColorEmoji'), url(fonts/NotoColorEmoji.ttf) format('truetype');
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.cardsprite {
    background-image: url(img/cards.svg);
    cursor: pointer;
}

.halfheightcard_wrap {
    display: inline-block;
    height: 121.5px;
    width: 84px
}

.halfheightcard {
    transform: scale(.5)
}

.fullheightcard {
    transform: scale(1);
}

.halfplusheightcard_wrap {
    display: inline-block;
    height: 361.5px;
    width: 252px
}

.halfplusheightcard {
    transform: scale(1.5)
}

.DIAMOND_ACE {
    background-position: 0 -243px
}

.DIAMOND_ACE,.DIAMOND_TWO {
    width: 168px;
    height: 243px
}

.DIAMOND_TWO {
    background-position: -167px -243px
}

.DIAMOND_THREE {
    background-position: -335px -243px
}

.DIAMOND_THREE,.DIAMOND_FOUR {
    width: 168px;
    height: 243px
}

.DIAMOND_FOUR {
    background-position: -502px -243px
}

.DIAMOND_FIVE {
    background-position: -670px -243px
}

.DIAMOND_FIVE,.DIAMOND_SIX {
    width: 168px;
    height: 243px
}

.DIAMOND_SIX {
    background-position: -837px -243px
}

.DIAMOND_SEVEN {
    background-position: -1005px -243px
}

.DIAMOND_SEVEN,.DIAMOND_EIGHT {
    width: 168px;
    height: 243px
}

.DIAMOND_EIGHT {
    background-position: -1172px -243px
}

.DIAMOND_NINE {
    background-position: -1340px -243px
}

.DIAMOND_NINE,.DIAMOND_TEN {
    width: 168px;
    height: 243px
}

.DIAMOND_TEN {
    background-position: -1507px -243px
}

.DIAMOND_JACK {
    background-position: -1675px -243px
}

.DIAMOND_JACK,.DIAMOND_QUEEN {
    width: 168px;
    height: 243px
}

.DIAMOND_QUEEN {
    background-position: -1842px -243px
}

.DIAMOND_KING {
    background-position: -2010px -243px
}

.DIAMOND_KING,.DIAMOND_14 {
    width: 168px;
    height: 243px
}

.DIAMOND_14 {
    background-position: 0 -243px
}

.DIAMOND_15 {
    background-position: -167px -243px
}

.DIAMOND_15,.DIAMOND_16 {
    width: 168px;
    height: 243px
}

.DIAMOND_16 {
    background-position: -167px -972px
}

.DIAMOND_17 {
    background-position: -335px -243px
}

.CLUB_ACE,.DIAMOND_17 {
    width: 168px;
    height: 243px
}

.CLUB_ACE {
    background-position: 0 0
}

.CLUB_TWO {
    background-position: -167px 0
}

.CLUB_TWO,.CLUB_THREE {
    width: 168px;
    height: 243px
}

.CLUB_THREE {
    background-position: -335px 0
}

.CLUB_FOUR {
    background-position: -502px 0
}

.CLUB_FOUR,.CLUB_FIVE {
    width: 168px;
    height: 243px
}

.CLUB_FIVE {
    background-position: -670px 0
}

.CLUB_SIX {
    background-position: -837px 0
}

.CLUB_SIX,.CLUB_SEVEN {
    width: 168px;
    height: 243px
}

.CLUB_SEVEN {
    background-position: -1005px 0
}

.CLUB_EIGHT {
    background-position: -1172px 0
}

.CLUB_EIGHT,.CLUB_NINE {
    width: 168px;
    height: 243px
}

.CLUB_NINE {
    background-position: -1340px 0
}

.CLUB_TEN {
    background-position: -1507px 0
}

.CLUB_TEN,.CLUB_JACK {
    width: 168px;
    height: 243px
}

.CLUB_JACK {
    background-position: -1675px 0
}

.CLUB_QUEEN {
    background-position: -1842px 0
}

.CLUB_QUEEN,.CLUB_KING {
    width: 168px;
    height: 243px
}

.CLUB_KING {
    background-position: -2010px 0
}

.CLUB_14 {
    background-position: 0 0
}

.CLUB_14,.CLUB_15 {
    width: 168px;
    height: 243px
}

.CLUB_15 {
    background-position: -167px 0
}

.CLUB_16 {
    background-position: 0 -972px
}

.CLUB_16,.CLUB_17 {
    width: 168px;
    height: 243px
}

.CLUB_17 {
    background-position: -335px 0
}

.HEART_ACE {
    background-position: 0 -486px
}

.HEART_ACE,.HEART_TWO {
    width: 168px;
    height: 243px
}

.HEART_TWO {
    background-position: -167px -486px
}

.HEART_THREE {
    background-position: -335px -486px
}

.HEART_THREE,.HEART_FOUR {
    width: 168px;
    height: 243px
}

.HEART_FOUR {
    background-position: -502px -486px
}

.HEART_FIVE {
    background-position: -670px -486px
}

.HEART_FIVE,.HEART_SIX {
    width: 168px;
    height: 243px
}

.HEART_SIX {
    background-position: -837px -486px
}

.HEART_SEVEN {
    background-position: -1005px -486px
}

.HEART_SEVEN,.HEART_EIGHT {
    width: 168px;
    height: 243px
}

.HEART_EIGHT {
    background-position: -1172px -486px
}

.HEART_NINE {
    background-position: -1340px -486px
}

.HEART_NINE,.HEART_TEN {
    width: 168px;
    height: 243px
}

.HEART_TEN {
    background-position: -1507px -486px
}

.HEART_JACK {
    background-position: -1675px -486px
}

.HEART_JACK,.HEART_QUEEN {
    width: 168px;
    height: 243px
}

.HEART_QUEEN {
    background-position: -1842px -486px
}

.HEART_KING {
    background-position: -2010px -486px
}

.HEART_KING,.HEART_14 {
    width: 168px;
    height: 243px
}

.HEART_14 {
    background-position: 0 -486px
}

.HEART_15 {
    background-position: -167px -486px
}

.HEART_15,.HEART_16 {
    width: 168px;
    height: 243px
}

.HEART_16 {
    background-position: -167px -972px
}

.HEART_17 {
    background-position: -335px -486px
}

.HEART_17,.SPADE_ACE {
    width: 168px;
    height: 243px
}

.SPADE_ACE {
    background-position: 0 -729px
}

.SPADE_TWO {
    background-position: -167px -729px
}

.SPADE_TWO,.SPADE_THREE {
    width: 168px;
    height: 243px
}

.SPADE_THREE {
    background-position: -335px -729px
}

.SPADE_FOUR {
    background-position: -502px -729px
}

.SPADE_FOUR,.SPADE_FIVE {
    width: 168px;
    height: 243px
}

.SPADE_FIVE {
    background-position: -670px -729px
}

.SPADE_SIX {
    background-position: -837px -729px
}

.SPADE_SIX,.SPADE_SEVEN {
    width: 168px;
    height: 243px
}

.SPADE_SEVEN {
    background-position: -1005px -729px
}

.SPADE_EIGHT {
    background-position: -1172px -729px
}

.SPADE_EIGHT,.SPADE_NINE {
    width: 168px;
    height: 243px
}

.SPADE_NINE {
    background-position: -1340px -729px
}

.SPADE_TEN {
    background-position: -1507px -729px
}

.SPADE_TEN,.SPADE_JACK {
    width: 168px;
    height: 243px
}

.SPADE_JACK {
    background-position: -1675px -729px
}

.SPADE_QUEEN {
    background-position: -1842px -729px
}

.SPADE_QUEEN,.SPADE_KING {
    width: 168px;
    height: 243px
}

.SPADE_KING {
    background-position: -2010px -729px
}

.SPADE_14 {
    background-position: 0 -729px
}

.SPADE_14,.SPADE_15 {
    width: 168px;
    height: 243px
}

.SPADE_15 {
    background-position: -167px -729px
}

.SPADE_16 {
    background-position: 0 -972px
}

.SPADE_16,.SPADE_17 {
    width: 168px;
    height: 243px
}

.SPADE_17 {
    background-position: -335px -729px
}

.WILD_JOKER {
    background-position: 0 -972px
}

.WILD_JOKER {
    width: 168px;
    height: 243px
}

.joker_red {
    background-position: -167px -972px
}

.card_back {
    background-position: -335px -972px;
    width: 168px;
    height: 243px
}

@media screen and (max-width: 800px) {
    #tablecards,.spectatorMode #tablecards {
        width:240px;
        flex-direction: column;
        justify-content: flex-start;
        float: none;
        margin: 10px auto
    }

    #myhand_wrap,#mymelds_wrap {
        width: 95%;
        margin-left: auto;
        margin-right: auto
    }
}

#myhand {
    width: 100%;
    margin: 20px auto;
    height: 130px
}

@media screen and (min-width: 1351px) {
    #tablecards {
        width:240px;
        flex-direction: column;
        justify-content: flex-start;
        float: left;
        margin: 10px
    }
}

#myhand_wrap {
    margin-top: 10px;
    margin-left: auto;
    margin-right: 10px;
    width: 76%;
    min-height: 160px;
    border-radius: 10px;
    border: 2px solid red;
    color: #fff;
    background-image: url(img/green_mat.jpg);
    background-repeat: repeat
}

.whiteblock {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-image: url(img/layout/rounded_b.png);
}

.playermelds {
    width: 95%;
    height: calc(100% - 64px);
    min-height: calc(100% - 64px);
    margin: 10px
}

.playermelds.whiteblock {
    padding: 2px;
    float: right;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap
}

.meld {
    float: left;
    min-width: 70px;
    min-height: 110px;
    display: block;
    margin: 5px;
    font-weight: 700;
}

#mymelds_wrap .meld_rank.haf_mymeld_size_small  {
    height: 87px;
    width: 60px;
    line-height: 87px
}

#mymelds_wrap .meld_rank.haf_mymeld_size_medium {
    height: 121.5px;
    width: 84px;
    line-height: 121.5px
}

#mymelds_wrap .meld_rank.haf_mymeld_size_large {
    height: 182.25px;
    width: 126px;
    line-height: 182.25px
}

#mymelds_wrap .haf_mymeld_size_small .meldcard {
    transform: scale(.3571)
}

#mymelds_wrap .haf_mymeld_size_medium .meldcard {
    transform: scale(.5)
}

#mymelds_wrap .haf_mymeld_size_large .meldcard {
    transform: scale(.75)
}

.opponent .meld_rank.haf_mymeld_size_small {
    height: 87px;
    width: 60px;
    line-height: 87px
}

.opponent .meld_rank.haf_mymeld_size_medium {
    height: 121.5px;
    width: 84px;
    line-height: 121.5px
}

.opponent .meld_rank.haf_mymeld_size_large {
    height: 182.25px;
    width: 126px;
    line-height: 182.25px
}

.opponent .meld_rank .haf_mymeld_size_small .meldcard {
    transform: scale(.3571)
}

.opponent .meld_rank.haf_mymeld_size_medium .meldcard {
    transform: scale(.5)
}

.opponent .meld_rank.haf_mymeld_size_large .meldcard {
    transform: scale(.75)
}

.meld_rank {
    display: block;
    margin: 2px;
    cursor: pointer
}

.meldnewmeld {
    width: 100%;
    height: 100%;
    border: 2px solid #000;
    color: #000;
    border-radius: 6px;
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
    text-align: center
}

.meld.meld_complete {
    /*width: 110px;*/
    /*height: 70px;*/
    z-index: 51
}

.meld_complete>.meld_rank {
    transform: rotate(90deg)
}

.meld_complete .meld_numbers.meld_hide_when_done {
    visibility: hidden
}

.meld_numbers {
    font-weight: 700;
    margin: 0 14px;
    text-align: center
}

.meld_rank_count {
    color: red
}

.meld_wild_count {
    color: #826105;
    margin-left: 3px
}

.meld_joker_count {
    color: #fcd303;
    margin-left: 3px
}

.meld_clean {
    border: 2px solid #00000000
}

.meld_clean .meld_wild_count,.meld_meld .meld_joker_count {
    visibility: hidden
}

.meld_dirty {
    border: 2px solid #00000000
}

.meld_wild {
    border: 2px solid #fcd303
}

.meld_red3 {
    border: 2px solid red
}

.meld_red3+.meld_numbers .meld_wild_count,.meld_red3+.meld_numbers .meld_joker_count {
    visibility: hidden
}

.card_type_icon {
    background: no-repeat;
    display: inline-block;
    margin-left: 6px;
    vertical-align: middle;
    width: 16px;
    height: 16px
}

.spade-icon {
    background-position: 0 0
}

.heart-icon,.spade-icon {
    background-image: url(img/suit-icons.png)
}

.heart-icon {
    background-position: -16px 0
}

.club-icon {
    background-position: -48px 0
}

.club-icon,.diamond-icon {
    background-image: url(img/suit-icons.png)
}

.diamond-icon {
    background-position: -32px 0
}

.joker-icon,.joker_black-icon,.joker_red-icon {
    background-image: url(img/suit-icons.png);
    background-position: -64px 0
}

.blankcard {
    background-color: #fff;
    width: 168px;
    height: 243px;
}

.blankcard,.cardsprite {
    display: inline-block;
    transform-origin: left top
}

.halfheightcard_wrap {
    display: inline-block;
    height: 121.5px;
    width: 84px
}

.halfheightcard {
    transform: scale(.5)
}

.halfplusheightcard_wrap {
    display: inline-block;
    height: 361.5px;
    width: 252px
}

.halfplusheightcard {
    transform: scale(1.5)
}

.diamond_1 {
    background-position: 0 -243px
}

.diamond_1,.diamond_2 {
    width: 168px;
    height: 243px
}

.diamond_2 {
    background-position: -167px -243px
}

.diamond_3 {
    background-position: -335px -243px
}

.diamond_3,.diamond_4 {
    width: 168px;
    height: 243px
}

.diamond_4 {
    background-position: -502px -243px
}

.diamond_5 {
    background-position: -670px -243px
}

.diamond_5,.diamond_6 {
    width: 168px;
    height: 243px
}

.diamond_6 {
    background-position: -837px -243px
}

.diamond_7 {
    background-position: -1005px -243px
}

.diamond_7,.diamond_8 {
    width: 168px;
    height: 243px
}

.diamond_8 {
    background-position: -1172px -243px
}

.diamond_9 {
    background-position: -1340px -243px
}

.diamond_9,.diamond_10 {
    width: 168px;
    height: 243px
}

.diamond_10 {
    background-position: -1507px -243px
}

.diamond_11 {
    background-position: -1675px -243px
}

.diamond_11,.diamond_12 {
    width: 168px;
    height: 243px
}

.diamond_12 {
    background-position: -1842px -243px
}

.diamond_13 {
    background-position: -2010px -243px
}

.diamond_13,.diamond_14 {
    width: 168px;
    height: 243px
}

.diamond_14 {
    background-position: 0 -243px
}

.diamond_15 {
    background-position: -167px -243px
}

.diamond_15,.diamond_16 {
    width: 168px;
    height: 243px
}

.diamond_16 {
    background-position: -167px -972px
}

.diamond_17 {
    background-position: -335px -243px
}

.club_1,.diamond_17 {
    width: 168px;
    height: 243px
}

.club_1 {
    background-position: 0 0
}

.club_2 {
    background-position: -167px 0
}

.club_2,.club_3 {
    width: 168px;
    height: 243px
}

.club_3 {
    background-position: -335px 0
}

.club_4 {
    background-position: -502px 0
}

.club_4,.club_5 {
    width: 168px;
    height: 243px
}

.club_5 {
    background-position: -670px 0
}

.club_6 {
    background-position: -837px 0
}

.club_6,.club_7 {
    width: 168px;
    height: 243px
}

.club_7 {
    background-position: -1005px 0
}

.club_8 {
    background-position: -1172px 0
}

.club_8,.club_9 {
    width: 168px;
    height: 243px
}

.club_9 {
    background-position: -1340px 0
}

.club_10 {
    background-position: -1507px 0
}

.club_10,.club_11 {
    width: 168px;
    height: 243px
}

.club_11 {
    background-position: -1675px 0
}

.club_12 {
    background-position: -1842px 0
}

.club_12,.club_13 {
    width: 168px;
    height: 243px
}

.club_13 {
    background-position: -2010px 0
}

.club_14 {
    background-position: 0 0
}

.club_14,.club_15 {
    width: 168px;
    height: 243px
}

.club_15 {
    background-position: -167px 0
}

.club_16 {
    background-position: 0 -972px
}

.club_16,.club_17 {
    width: 168px;
    height: 243px
}

.club_17 {
    background-position: -335px 0
}

.heart_1 {
    background-position: 0 -486px
}

.heart_1,.heart_2 {
    width: 168px;
    height: 243px
}

.heart_2 {
    background-position: -167px -486px
}

.heart_3 {
    background-position: -335px -486px
}

.heart_3,.heart_4 {
    width: 168px;
    height: 243px
}

.heart_4 {
    background-position: -502px -486px
}

.heart_5 {
    background-position: -670px -486px
}

.heart_5,.heart_6 {
    width: 168px;
    height: 243px
}

.heart_6 {
    background-position: -837px -486px
}

.heart_7 {
    background-position: -1005px -486px
}

.heart_7,.heart_8 {
    width: 168px;
    height: 243px
}

.heart_8 {
    background-position: -1172px -486px
}

.heart_9 {
    background-position: -1340px -486px
}

.heart_9,.heart_10 {
    width: 168px;
    height: 243px
}

.heart_10 {
    background-position: -1507px -486px
}

.heart_11 {
    background-position: -1675px -486px
}

.heart_11,.heart_12 {
    width: 168px;
    height: 243px
}

.heart_12 {
    background-position: -1842px -486px
}

.heart_13 {
    background-position: -2010px -486px
}

.heart_13,.heart_14 {
    width: 168px;
    height: 243px
}

.heart_14 {
    background-position: 0 -486px
}

.heart_15 {
    background-position: -167px -486px
}

.heart_15,.heart_16 {
    width: 168px;
    height: 243px
}

.heart_16 {
    background-position: -167px -972px
}

.heart_17 {
    background-position: -335px -486px
}

.heart_17,.spade_1 {
    width: 168px;
    height: 243px
}

.spade_1 {
    background-position: 0 -729px
}

.spade_2 {
    background-position: -167px -729px
}

.spade_2,.spade_3 {
    width: 168px;
    height: 243px
}

.spade_3 {
    background-position: -335px -729px
}

.spade_4 {
    background-position: -502px -729px
}

.spade_4,.spade_5 {
    width: 168px;
    height: 243px
}

.spade_5 {
    background-position: -670px -729px
}

.spade_6 {
    background-position: -837px -729px
}

.spade_6,.spade_7 {
    width: 168px;
    height: 243px
}

.spade_7 {
    background-position: -1005px -729px
}

.spade_8 {
    background-position: -1172px -729px
}

.spade_8,.spade_9 {
    width: 168px;
    height: 243px
}

.spade_9 {
    background-position: -1340px -729px
}

.spade_10 {
    background-position: -1507px -729px
}

.spade_10,.spade_11 {
    width: 168px;
    height: 243px
}

.spade_11 {
    background-position: -1675px -729px
}

.spade_12 {
    background-position: -1842px -729px
}

.spade_12,.spade_13 {
    width: 168px;
    height: 243px
}

.spade_13 {
    background-position: -2010px -729px
}

.spade_14 {
    background-position: 0 -729px
}

.spade_14,.spade_15 {
    width: 168px;
    height: 243px
}

.spade_15 {
    background-position: -167px -729px
}

.spade_16 {
    background-position: 0 -972px
}

.spade_16,.spade_17 {
    width: 168px;
    height: 243px
}

.spade_17 {
    background-position: -335px -729px
}

.joker_black {
    background-position: 0 -972px
}

.joker_black,.joker_red {
    width: 168px;
    height: 243px
}

.joker_red {
    background-position: -167px -972px
}

.card_back {
    background-position: -335px -972px;
    width: 168px;
    height: 243px
}

.meld_clean_THREE {
    background-position: -335px -729px;
    width: 168px;
    height: 243px
}

.meld_clean_FOUR {
    background-position: -502px -486px;
    width: 168px;
    height: 243px
}

.meld_clean_FIVE {
    background-position: -670px -486px;
    width: 168px;
    height: 243px
}

.meld_clean_SIX {
    background-position: -837px -486px;
    width: 168px;
    height: 243px
}

.meld_clean_SEVEN {
    background-position: -1005px -486px;
    width: 168px;
    height: 243px
}

.meld_clean_EIGHT {
    background-position: -1172px -486px;
    width: 168px;
    height: 243px
}

.meld_clean_NINE {
    background-position: -1340px -486px;
    width: 168px;
    height: 243px
}

.meld_clean_TEN {
    background-position: -1507px -486px;
    width: 168px;
    height: 243px
}

.meld_clean_JACK {
    background-position: -1675px -486px;
    width: 168px;
    height: 243px
}

.meld_clean_QUEEN {
    background-position: -1842px -486px;
    width: 168px;
    height: 243px
}

.meld_clean_KING {
    background-position: -2010px -486px;
    width: 168px;
    height: 243px
}

.meld_clean_ACE {
    background-position: 0 -486px;
    width: 168px;
    height: 243px
}

.meld_dirty_FOUR {
    background-position: -502px -729px;
    width: 168px;
    height: 243px
}

.meld_dirty_FIVE {
    background-position: -670px -729px;
    width: 168px;
    height: 243px
}

.meld_dirty_SIX {
    background-position: -837px -729px;
    width: 168px;
    height: 243px
}

.meld_dirty_SEVEN {
    background-position: -1005px -729px;
    width: 168px;
    height: 243px
}

.meld_dirty_EIGHT {
    background-position: -1172px -729px;
    width: 168px;
    height: 243px
}

.meld_dirty_NINE {
    background-position: -1340px -729px;
    width: 168px;
    height: 243px
}

.meld_dirty_TEN {
    background-position: -1507px -729px;
    width: 168px;
    height: 243px
}

.meld_dirty_JACK {
    background-position: -1675px -729px;
    width: 168px;
    height: 243px
}

.meld_dirty_QUEEN {
    background-position: -1842px -729px;
    width: 168px;
    height: 243px
}

.meld_dirty_KING {
    background-position: -2010px -729px;
    width: 168px;
    height: 243px
}

.meld_dirty_ACE {
    background-position: 0 -729px;
    width: 168px;
    height: 243px
}

.meld_black_3 {
    background-position: -335px -729px;
    width: 168px;
    height: 243px
}

.meld_red3_17 {
    background-position: -335px -486px;
    width: 168px;
    height: 243px
}

.meld_wild_img {
    background-position: -167px -972px;
    width: 168px;
    height: 243px
}

#wholetable {
    border-radius: 20px;
    display: block;
    overflow: hidden
}

.spectatorMode #myhand_wrap,.spectatorMode .teaminfo_spectator {
    display: none
}
.stockitem {
    background-image: url("img/green_mat.jpg")
}
.spectatorMode #tablecards {
    width: 80%;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 10px auto;
    float: none
}

.spectatorMode #tablecards #table_info_2 {
    display: none
}

.haf_hand_size_small #myhand_wrap .cardsprite {
    transform: scale(.3571)
}

.haf_hand_size_medium #myhand_wrap .cardsprite {
    transform: scale(.5)
}

.haf_hand_size_large #myhand_wrap .cardsprite {
    transform: scale(.75)
}

.haf_hand_size_small #myhand_wrap .stockitem {
    height: 87px;
    width: 60px;
    line-height: 87px
}

.haf_hand_size_medium #myhand_wrap .stockitem {
    height: 122px;
    width: 84px;
    line-height: 122px
}

.haf_hand_size_large #myhand_wrap .stockitem {
    height: 184px;
    width: 126px;
    line-height: 184px
}

#myhand_outer {
    width: 96%;
    margin: 10px auto;
    border: 4px solid transparent
}

#myhand {
    width: 100%;
    margin: 20px auto;
    cursor: pointer
}

#myhand .stockitem_selected {
    margin-top: -20px
}

#myhand_labels {
    margin-top: 4px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}

.card_tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    font-size: large
}

.spectatorMode #mymelds_wrap {
    display: none
}

#mymelds_wrap {
    margin-top: 10px;
    margin-left: auto;
    margin-right: 10px;
    width: 76%;
    min-height: 160px;
    overflow: hidden;
    border-radius: 10px;
    border: 2px solid red;
    color: #fff;
    background-image: url(img/green_mat.jpg);
    background-repeat: repeat
}

#mymelds_info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    font-weight: 700
}

#mymelds {
    min-height: 160px;
    margin: 20px 10px 10px;
    clear: both;
    float: none
}

#tablecards {
    display: flex;
    flex-wrap: nowrap;
    float: left;
    align-content: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    background: green;
    border-radius: 10px
}

@media screen and (max-width: 800px) {
    #tablecards,.spectatorMode #tablecards {
        width:240px;
        flex-direction: column;
        justify-content: flex-start;
        float: none;
        margin: 10px auto
    }

    #myhand_wrap,#mymelds_wrap {
        width: 95%;
        margin-left: auto;
        margin-right: auto
    }
}

@media screen and (min-width: 801px) and (max-width:1350px) {
    #tablecards {
        width:80%;
        flex-direction: row;
        justify-content: space-around;
        margin: 10px auto;
        float: none
    }

    #myhand_wrap,#mymelds_wrap {
        width: 95%;
        margin-left: auto;
        margin-right: auto
    }
}

@media screen and (min-width: 1351px) {
    #tablecards {
        width:240px;
        flex-direction: column;
        justify-content: flex-start;
        float: left;
        margin: 10px
    }
}

.opponent {
    background-image: url(img/green_mat.jpg);
    background-repeat: repeat;
    color: #fff;
    border: 2px solid #00f;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;
    float: none
}

.table_info_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    font-weight: 700;
    color: #fff
}

#gameheader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center
}

#help_button_spacer {
    width: 32px;
    visibility: hidden
}

#round_info {
    width: 100%;
    margin: 4px auto 0;
    background-image: url(img/green_mat.jpg);
    background-repeat: repeat;
    border-radius: 10px
}

#round_info .text,#round_number_label,#current_player_label {
    margin-left: 10px
}

#round_minimum,#round_number,#current_player {
    display: inline-block;
    margin-right: 10px;
}

#round_minimum_label {
    margin-left: 10px
}

.spectatorMode #round_minimum,.spectatorMode #round_minimum_label {
    display: none
}

#round_to_go_box {
    display: inline-block;
    background-image: url(img/green_mat.jpg);
    background-repeat: repeat;
    min-width: 90%;
    border-radius: 10px;
    margin: 4px auto;
    border: 2px solid red
}

#round_to_go_label {
    margin-left: 10px;
    display: inline-block
}

#round_to_go {
    margin-right: 4px;
    display: inline-block
}

#take_discard_box {
    display: inline-block;
    background-image: url(img/green_mat.jpg);
    background-repeat: repeat;
    min-width: 90%;
    border-radius: 10px;
    margin: 4px auto 0;
    border: 2px solid red
}

#take_discard_rank_label {
    margin-left: 10px
}

#take_discard_rank {
    margin-right: 4px;
    display: inline-block
}

#take_discard_count_label {
    margin-left: 10px
}

#take_discard_count {
    margin-right: 4px;
    display: inline-block
}

.round_info {
    margin: 0 auto
}

.card_piles {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin: 2px auto
}

.carddeck_wrap {
    height: 166px;
    width: 92px;
    margin: 6px auto 5px;
    padding-top: 5px
}

.carddeck {
    height: 122px;
    width: 84px;
    margin: 4px;
    z-index: 2;
    background: orange
}

.cardcounter {
    width: 84px;
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    margin: 0 auto;
    background-image: url(img/green_mat.jpg);
    background-repeat: repeat
}

#discardcounter {
    position: relative;
    top: -18px
}

#haf_undo_button {
    display: inline-block;
    cursor: pointer;
    height: 20px;
    width: 50%;
    min-width: 10em;
    margin: 15px auto 4px;
    border-radius: 6px;
    border: 3px solid grey;
    background: #fff;
    color: red;
    font-weight: 700;
    text-align: center
}

@keyframes pulsing_bg {
    0% {
        background: transparent
    }

    50% {
        background: #add8e6
    }

    to {
        background: transparent
    }
}

@keyframes pulsing_border {
    0% {
        border-color: transparent
    }

    50% {
        border-color: #00008b
    }

    to {
        border-color: transparent
    }
}

.pulsing_bg {
    animation: pulsing_bg 2s linear infinite normal none
}

.pulsing_border {
    animation: pulsing_border 3s linear 5s 8 normal none
}

#stock_wrap {
    margin-right: 2px
}

#stock_clip {
    display: block
}

#stock_hl {
    width: 92px
}

#discard_wrap {
    margin-left: 2px
}

#discard_clip {
    display: block
}

#discard_hl {
    width: 92px
}

#roundSummary {
    z-index: 95;
    position: absolute;
    top: 5%;
    left: 5%;
    width: 75vw;
    height: 90vh;
    background: #d3d3d3;
    color: #000;
    border: 2px solid red;
    border-radius: 20px
}

@media screen and (max-width: 550px) {
    #roundSummary {
        width:90%
    }
}

#roundSummaryHeader {
    min-width: 96%;
    height: 24px;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center
}

#roundSummaryHeader span {
    min-height: 20px;
    margin-left: 6px;
    padding: 2px
}

.roundSummaryTeam {
    display: inline-block;
    min-width: 15%;
    max-width: 31%;
    min-height: 24px;
    margin: 10px
}

.roundSummaryTeamLabel {
    font-weight: 700
}

#roundReport {
    min-width: 96%;
    height: 70%;
    margin: 10px;
    border: 2px solid #000;
    overflow: auto
}

#gameSummaryTitle,.roundSummaryTitle {
    margin: 10px auto;
    text-align: center
}

.reportTable {
    border-collapse: separate
}

.reportTable td:first-child {
    border-left: none
}

.reportTable td {
    margin-left: 4px;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    font-weight: 700;
    padding: 2px 6px
}

.reportTable thead td.report_go_out_column {
    border-left: none;
    margin-left: 6px
}

.report_team_header {
    background-color: #b3b9c4
}

.report_footer_final_column {
    border-right: 2px solid #000
}

#roundSummaryFooter {
    min-width: 96%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center
}

#haf_dismiss_button,#report_timer {
    height: 2ex;
    max-width: 15%;
    margin-left: auto;
    margin-right: auto
}

#report_timer {
    display: inline-block;
    padding: 3px;
    border-radius: 6px;
    border: 3px solid grey;
    background: #fff;
    color: red;
    font-weight: 700;
    text-align: center
}

.game_status {
    margin: 2px auto
}

.game_status,.teaminfo {
    text-align: center;
    border-radius: 10px 10px 0 0;
    font-weight: 700
}

.teaminfo {
    margin: 2px 10px
}

.playertable {
    min-width: 45%;
    text-align: center;
    padding: 0;
    margin: 0;
    border: 2px solid red
}

.playerinfo {
    min-height: 40px;
    width: 100%;
    margin: auto
}

.playerinfo,.playertablename {
    border-radius: 10px 10px 0 0;
    background: #fff
}

.playertablename {
    font-weight: 700;
    text-align: center;
    min-width: 12em
}

.playerhandcount {
    width: 80px;
    height: 32px;
    float: right
}

.teamnames {
    width: 100%;
    margin: auto;
    background: #fff
}

.teammember {
    display: inline-block;
    background: #fff;
    border: 1px solid green;
    width: 47%;
    overflow: hidden;
    overflow-wrap: break-word;
    text-align: center
}

.target_contract {
    display: unset;
    background-image: url(img/green_mat.jpg);
    background-repeat: repeat;
    color: #fff;
    min-width: 90%;
    font-weight: 700;
    border-radius: 10px;
    margin: 4px auto
}

.contractprogress {
    display: inline-block;
    margin-right: 10px;
    margin-top: 2px;
    float: right;
    width: 200px;
    font-weight: 700
}

.target_contract .contractbar {
    margin: 0 auto;
    min-height: 16px;
    overflow: hidden;
    padding-bottom: 4px;
    padding-left: 4px
}

.contractbar {
    width: 160px;
    min-height: 16px;
    text-align: left
}

.contract2 .contractsection {
    width: 45%
}

.contract3 .contractsection {
    width: 30%
}

.contract4 .contractsection {
    width: 22%
}

.contract5 .contractsection {
    width: 17%
}

.contractsection {
    display: inline-block;
    height: 16px;
    overflow: hidden;
    margin: 0 2px
}

.contractsection_none {
    background: #fff
}

.contractsection_clean {
    background: #00f
}

.contractsection_dirty {
    background: #826105
}

.contractsection_wild {
    background: #fcd303
}

.contractsection_cleanwild {
    background: repeating-linear-gradient(to left top,#fcd303,#fcd303 15%,#00f 0,#00f 50%,#fcd303 0,#fcd303 65%,#00f 0)
}

.contractsection_dirtywild {
    background: repeating-linear-gradient(to left bottom,#fcd303,#fcd303 15%,#826105 0,#826105 50%,#fcd303 0,#fcd303 65%,#826105 0)
}

.contractsection_any {
    background: repeating-linear-gradient(to left top,#00f,#00f 20%,#826105 0,#826105 40%,#00f 0)
}

.bluecheckicon {
    background-position: -96px 0;
    display: inline-block;
    float: right
}

.bluecheckicon,.playerdealingicon {
    background-image: url(img/gameboard-icons.png);
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    visibility: visible
}

.playerdealingicon {
    clear: both;
    position: absolute;
    background-position: -64px 0;
    margin-top: -20px;
    z-index: 90
}

.playerdealingicon_visible {
    background-image: url(img/gameboard-icons.png);
    visibility: visible
}

.playerfooticon {
    background-position: -32px 0
}

.playerfooticon,.playerhandicon {
    background-image: url(img/gameboard-icons.png);
    display: inline-block;
    min-width: 32px;
    width: 32px;
    height: 32px;
    z-index: 90;
    visibility: visible
}

.playerhandicon {
    background-position: 0 0;
    min-height: 32px
}

.helpicon {
    background-image: url(img/gameboard-icons.png);
    background-position: -128px 0;
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    visibility: visible
}

.game_box,.helpicon {
    display: inline-block
}

.game_box {
    background-image: url(img/game_box75.png);
    background-position: 0 0;
    min-width: 75px;
    min-height: 75px;
    width: 75px;
    height: 75px
}

.tableheader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center
}

#game_box {
    margin-bottom: 4px
}

#help_dialog {
    font-size: large;
    width: 400px
}

#help_dialog .contractsection {
    width: 15px
}

#help_dialog li {
    list-style-type: disc;
    list-style-position: inside
}

.opponentteaminfo {
    margin-top: 4px;
    width: 90%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    font-weight: 700;
    text-align: center
}

.haf_scores {
    margin-top: 4px;
    float: right;
    display: inline-block
}

.haf_total_score_label {
    clear: both
}

@media screen and (max-width: 1015px) {
    .opponent {
        width:95%
    }
    .opponent .playermelds {
        width: 95%;
        min-height: 120px;
        margin: 10px auto;
        float: none
    }
}

@media screen and (min-width: 1016px) and (max-width:1300px) {
    .opponent {
        width:95%
    }

    .opponentteaminfo {
        max-width: 240px;
        min-height: 160px;
        margin-left: auto;
        margin-right: auto
    }

    .opponent .playermelds {
        width: 65%;
        min-height: 120px;
        margin: 10px
    }
}

@media screen and (min-width: 1301px) {
    .opponent {
        width:95%;
        min-height: 160px
    }

    .opponentteaminfo {
        margin-left: 10px;
        margin-right: 10px
    }

    .opponent .playermelds {
        min-height: 120px;
        margin: 10px;
        float: none
    }
}
